import { useAuthContext } from '@/auth/useAuthContext';
import { useSnackbar } from '@/components/snackbar';
import { SkeeloApi } from '@/services/api';
import { CircularProgress, Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Head from 'next/head';
import { useEffect, useState } from 'react';

import { _appFeatured } from '../_mock/arrays';
import {
  CheckInIllustration,
  CheckOutIllustration,
  SeoIllustration,
} from '../assets/illustrations';
import { useSettingsContext } from '../components/settings';
import DashboardLayout from '../layouts/dashboard';
import {
  AppCurrentDownload,
  AppFeatured,
  AppTopAuthors,
  AppWelcome,
  AppWidget,
  AppWidgetSummary,
} from '../sections/@cms/app';
import { BookingWidgetSummary } from '../sections/@cms/booking';

const skeeloApi = new SkeeloApi();

GeneralAppPage.getLayout = (page: React.ReactElement) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default function GeneralAppPage() {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardInfo, setDashboardInfo] = useState<any>(null);

  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const [isLoading, setIsLoading] = useState(false);
  const retrieveDashboardInfo = async () => {
    setIsLoading(true);

    try {
      const response = await skeeloApi.user.retrieveDashboardInfo();
      console.log(`app:${JSON.stringify(response.data.app)}`);
      console.log(`platform${JSON.stringify(response.data.platform)}`);
      console.log(`full${JSON.stringify(response.data)}`);
      setDashboardInfo(response.data);
      setIsLoading(false);
    } catch (_) {
      setIsLoading(false);
      enqueueSnackbar('Houve um erro inesperado. Tente novamente!', {
        variant: 'error',
      });
    }
  };

  const currentInfo = dashboardInfo && (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <AppWelcome
            title={`Bem-vindo de volta, ${user?.name.split(' ')[0]}!`}
            description="Abaixo estão as métricas da sua empresa."
            img={
              <SeoIllustration
                sx={{
                  p: 3,
                  width: 360,
                  margin: { xs: 'auto', md: 'inherit' },
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AppFeatured list={_appFeatured} />
        </Grid>

        <Grid item xs={12} md={4}>
          <AppWidgetSummary
            title="Usuários elegíveis"
            percent={dashboardInfo?.elegible?.user_elegible_growth ?? 'N/A'}
            total={dashboardInfo?.elegible?.user_elegible_count ?? 'N/A'}
            chart={{
              colors: [theme.palette.primary.main],
              series: dashboardInfo?.elegible?.series ?? [],
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AppWidgetSummary
            title="Usuários ativos na plataforma"
            percent={dashboardInfo?.platform?.registered_growth ?? 'N/A'}
            total={dashboardInfo?.platform?.registered_count ?? 'N/A'}
            chart={{
              colors: [theme.palette.info.main],
              series: dashboardInfo?.platform?.series ?? [],
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AppWidgetSummary
            title="Usuários ativos no app"
            percent={dashboardInfo?.app?.registered_growth ?? 'N/A'}
            total={dashboardInfo?.app?.registered_count ?? 'N/A'}
            chart={{
              colors: [theme.palette.info.main],
              series: dashboardInfo?.app?.series ?? [],
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AppWidget
            title="Conversão Plataforma"
            total={dashboardInfo?.platform?.registered_count ?? 'N/A'}
            icon="dashicons:admin-site"
            chart={{
              series: dashboardInfo?.platform?.conversion_rate ?? 0,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppWidget
            title="Conversão App"
            color="info"
            total={dashboardInfo?.app?.registered_count ?? 'N/A'}
            icon="material-symbols:phone-android-outline"
            chart={{
              series: dashboardInfo?.app?.conversion_rate ?? 0,
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <BookingWidgetSummary
            title="Livros iniciados"
            total={dashboardInfo?.started_book_count ?? 0}
            icon={<CheckInIllustration />}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <BookingWidgetSummary
            title="Livros finalizados"
            total={dashboardInfo?.finished_book_count ?? 0}
            icon={<CheckOutIllustration />}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AppTopAuthors
            title="Mais engajados"
            list={dashboardInfo?.engaged_users ?? []}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AppCurrentDownload
            title="Plataforma"
            chart={{
              colors: [
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.warning.main,
              ],
              series: [
                {
                  label: 'Android',
                  value: dashboardInfo?.operational_system?.android,
                },
                { label: 'iOS', value: dashboardInfo?.operational_system?.ios },
              ],
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );

  useEffect(() => {
    retrieveDashboardInfo();
  }, []);

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/24348484.js"
        />

        <title>Skeelo Corp</title>
      </Head>
      {isLoading ? <CircularProgress size={16} /> : currentInfo}
    </>
  );
}
